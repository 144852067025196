import { graphql } from 'gatsby';
import React from 'react';
import loadable from '@loadable/component';
import Layout from '../components/layout/Layout';
import Seo from '../components/layout/SEO';
import Hero from '../components/home/Hero';
const About = loadable(() => import('../components/home/About'));
const PracticeAreas = loadable(() => import('../components/home/PracticeAreas'));
const Pitch = loadable(() => import('../components/home/Pitch'));
const Partners = loadable(() => import('../components/home/Partners'));
const Testimonials = loadable(() => import('../components/home/Testimonials'));
const ContactBlog = loadable(() => import('../components/home/ContactBlog'));
const OurCases = loadable(() => import("../components/home/OurCases"));

const index = ({ data }) => {

  return (
    <Layout>
      <Seo
        title='Newburgh Personal Injury Attorney'
      />
      <Hero />
      <OurCases data={data.allResultsJson} />
      <Partners />
      <PracticeAreas />
      <About />
      <ContactBlog />
      <Pitch />
      <Testimonials data={data.allTestimonialsJson} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allResultsJson(limit: 9) {
      edges {
        node {
          award
          category
          id
          narrative
        }
      }
    }
    allTestimonialsJson(limit: 1) {
      edges {
        node {
          id
          author
          excerpt
        }
      }
    }
  }
`

export default index